import { SchedulesAction, SchedulesState } from '../types';

export const schedulesReducer = (
  state: SchedulesState,
  action: SchedulesAction
): SchedulesState => {
  switch (action.type) {
    case 'set-schedule':  return { 
        ...state,
        schedule: action.schedule.sort((a,b) => a.start > b.start ? 1 : -1)
    };
    case 'show-scheduler':  return { 
        ...state,
        addNew: true
    };
    case 'cancel-scheduler':  return { 
        ...state,
        addNew: false,
        newStartTime: new Date(),
        newEndTime: new Date()
    };
    case 'set-new-start-time':  return { 
        ...state,
        newStartTime: action.value
    };
    case 'set-new-end-time':  return { 
        ...state,
        newEndTime: action.value
    };
    case 'add-schedule': {
      state.schedule.push({
        start: action.start,
        stop: action.stop
      })  
      state.schedule.sort((a,b) => a.start > b.start ? 1 : -1)
      return { 
        ...state,
        scheduleChanged: true
      }
    };
    case 'delete-schedule': {
      state.schedule.splice(action.index, 1);  
      state.schedule.sort((a,b) => a.start > b.start ? 1 : -1)
      return { 
        ...state,
        scheduleChanged: true
      }
    };
    case 'changes-saved' : return { 
      ...state,
      scheduleChanged: false
    };
    default:
      throw new Error();
  }
};
