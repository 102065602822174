import { makeCancelablePromise } from "../../functions/make-cancelable-promise.function";
import { AjaxResponse, AjaxRequest } from "../types";

type Method = "GET" | "POST" | "PUT" | "DELETE";

export const ajax = (
  method: Method,
  url: string,
  data?: Record<string, any>,
  authorization?: string
): AjaxRequest => {
  const xhttp = new XMLHttpRequest();
  const { promise, cancel } = makeCancelablePromise<AjaxResponse>(
    new Promise((resolve, reject) => {
      xhttp.onreadystatechange = function () {
        if (this.readyState === 4) {
          if (this.status >= 200 && this.status <= 204) {
            try {
              const contentType = this.getResponseHeader("content-type");
              const response: AjaxResponse = {
                status: this.status,
                text: this.responseText,
                json: null,
              };
              if (contentType?.startsWith("application/json")) {
                response.json = JSON.parse(this.responseText);
              }
              return resolve(response);
            } catch (error: any) {
              return reject({ text: error.message, status: -500 });
            }
          }
          return reject({ text: this.statusText, status: this.status });
        }
      };
      xhttp.open(method, url, true);
      if (authorization) {
        xhttp.setRequestHeader("Authorization", `Bearer ${authorization}`);
      }
      if (data) {
        xhttp.setRequestHeader("Content-Type", "application/json");
        return xhttp.send(JSON.stringify(data));
      }
      xhttp.send();
    })
  );
  return {
    promise,
    cancel: () => {
      cancel();
      xhttp.abort();
    },
  };
};
