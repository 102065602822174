type DivElement = HTMLDivElement & { webkitRequestFullscreen?: () => void, msRequestFullscreen?: () => void }

export function openFullscreen(elem: DivElement | null) {
    if (elem === null)  return;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { // Safari 
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { // IE11 
      elem.msRequestFullscreen();
    }
  }