import { SummaryFields } from '../enums';
import { Device } from '../types';

export const siteDataConverter = (devices: Device[]) => devices.sort((a,b) => a.code > b.code ? 1 : -1).reduce((acc, cur) => {
    if(cur.status === 'ONLINE') {
        acc[SummaryFields.online].push(cur);
        if(cur.tags.some(tag => Boolean(tag.alarm))){
          acc[SummaryFields.alarms].push(cur);
        }
    }
    else {
        acc[SummaryFields.offline].push(cur);
    }
    return acc;
  }, {
    [SummaryFields.all]: devices,
    [SummaryFields.online]: [] as Device[],
    [SummaryFields.offline]: [] as Device[],
    [SummaryFields.alarms]: [] as Device[]
  });