import Grid from '@material-ui/core/Grid';
import { Device } from '../types';
import { DeviceKanbanCard } from './device-kanban-card.component';

type DeviceKanbanProps = {
    devices: Device[]
}

export function DeviceKanban({ devices }: DeviceKanbanProps) {
  return (
    <div >
      <Grid container spacing={1}>
        <Grid container  item xs={12} spacing={1}>   
          {
            devices.sort((a,b) => a.code > b.code ? 1 : -1).map((d) => ( <Grid item xs={12} sm={3} key={d._id}>
              <DeviceKanbanCard device={d} />
            </Grid>))
          }
        </Grid>
      </Grid>
    </div>
  );
}