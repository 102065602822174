import { useEffect } from 'react';

import { useAnalyticsContext } from '.';
import { useAuthRequest } from '../../auth';
import { logsApi, GetLogsRequest } from '../../api';
import { AnalyticalLog } from '../types';
import { toKiloRepresentation, hourFormat } from '../../common';
import { Analytic } from '../../automaton-tags';
import { xAxisLabels } from '../enums';

type Props = {
  anaTag: Analytic & { tag: string };
  refresh?: boolean;
};

export const useGraph = ({ anaTag, refresh }: Props) => {
  const { state } = useAnalyticsContext();
  const [request, response] = useAuthRequest<GetLogsRequest>((token, req) => {
    if (!req) throw new Error('Request Required');
    return logsApi.getLogs(req, token);
  });

  const sum: number = response?.json.logs
    .map((log: AnalyticalLog) => log.y)
    .reduce((a: number, b: number) => a + b, 0);
  const average: number = sum / response?.json.logs.length;

  const xAxisLabelTimeHandler = (label: any) => {
    if (xAxisLabels[state.reqType] === 'Hours') return hourFormat(label);
    else return label;
  };

  useEffect(() => {
    request({
      deviceId: state.deviceId,
      tag: anaTag.tag,
      year: state.selectedDate
        ? state.selectedDate.getFullYear()
        : new Date().getFullYear(),
      month: state.selectedDate
        ? state.selectedDate.getMonth()
        : new Date().getMonth(),
      day: state.selectedDate
        ? state.selectedDate.getDate()
        : new Date().getDate(),
      reqType: state.reqType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anaTag.tag, refresh, state.deviceId, state.selectedDate, state.reqType]);

  const yAxisLabelHandler = (label: any) => toKiloRepresentation(label, 1);

  return {
    graphState: state,
    response,
    sum,
    average,
    xAxisLabels,
    xAxisLabelTimeHandler,
    yAxisLabelHandler,
  };
};
