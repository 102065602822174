import { useReducer, ReactNode } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import { loginReducer } from '../functions';
import { AuthContext } from '../contexts';
import { LoginForm } from './login-form.component';
import { ForgotPassword } from './forgot-password.component';
import { Relogin } from './relogin.component';
import { getRefreshToken } from '../functions';


export const AuthenticationProvider = (props: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(loginReducer, {
    loggedIn: false
  });
  return (
    <AuthContext.Provider value={{ state, dispatch}}>
      {
        state.loggedIn 
          ? (<Redirect to={{ pathname: "/" }}/>)
          : getRefreshToken() 
            ? (<Redirect to={{ pathname: "/relogin" }}/>)
            : (<Redirect to={{ pathname: "/login" }}/>)
      }
      <Switch>
        <Route path="/relogin">            
          <Relogin />
        </Route>
        <Route path="/login">            
          <LoginForm />
        </Route>
        <Route path="/forgotpassword">
          <ForgotPassword />
        </Route>
        <Route path="/">
        {
          state.loggedIn 
            ? props.children
            :  getRefreshToken()
              ? (<Redirect to={{ pathname: "/relogin" }}/>)
              : (<Redirect to={{ pathname: "/login" }}/>)
        }
        </Route>
      </Switch>
    </AuthContext.Provider>
  );
};
