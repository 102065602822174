import { readLocalDb, writeLocalDb } from '../../lib';
import { UserAuth } from '../types';

export const getUserInfo = (): UserAuth  => readLocalDb('userinfo');

/**
 * if input is undefined sets to empty UserAuth (fields have value of '')
 * @param input 
 * @returns 
 */
export const setUserInfo = (input?: UserAuth)  => writeLocalDb('userinfo', input || {
    username: '',
    userId: '',
    userRole: '',
    org: ''
});