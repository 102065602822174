import { useEffect, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useLayoutNav } from '../../layout';
import { Device } from '../types';
import { LedIndicator, DebugJson } from '../../common';
import { grey, green, teal, lightBlue } from '@material-ui/core/colors';
import { useAutomatonTagsContext } from '../../automaton-tags/hooks/use-automaton-tags-context.hook';
import { useAutomatonTags } from '../../automaton-tags/hooks/use-automaton-tags.hook';
import { applyTagSpace } from '../../automaton-tags/functions/apply-tag-space.function';
import { AppliedSpace } from '../../automaton-tags/types';

type DeviceKanbanCardProps = {
  device: Device;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRootOnline: {
      color:  theme.palette.getContrastText(teal[400]),
      backgroundColor:teal[400],
      maxWidth: 345,
      minHeight: 100,
      marginBottom: theme.spacing(4),
    },
    cardRootOffline: {
      color:  theme.palette.getContrastText(grey[400]),
      backgroundColor:grey[400],
      maxWidth: 345,
      minHeight: 155,
      marginBottom: theme.spacing(4),
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    chipBoard: {
      justifyContent: 'center',
      display: 'flex',
      gap:16
    },
  })
);

const LED_COLORS: Record<string, "blue" | "green" | "red" | "yellow" | 'grey'> = {
  "1": 'green',
  "2": 'red',
  "3": 'blue',
  "4": 'yellow'
}

const makeLed = ({ledCreator, value, label }: AppliedSpace) => {
  if(!ledCreator) throw Error('ledCreator should be defined for items that need to show on live i.e., showOnLive: true')
  return (<div key={label}>
    <p>{ledCreator[value].description}</p>
    <LedIndicator color={ LED_COLORS[ledCreator[value].color]} />
  </div>);
};

const makeAlarmLed = (label: string, possibles: string[], space: AppliedSpace[]) => (<div key={label}>
  <p>{label}</p>
  <LedIndicator color={space.find(spec => possibles.includes(spec.alarm ?? '')) ? 'red' : 'grey'} />
</div>);




export function DeviceLiveCard({ device }: DeviceKanbanCardProps) {
  const classes = useStyles();
  const { state : { profileSetup: { alarms } }} = useAutomatonTagsContext();
  const { getSetup } = useAutomatonTags();
  const navigateToDevice = useLayoutNav();
  const [deviceData, setDeviceData] = useState<AppliedSpace[]>([])

  const siteOnlineStatusBar = (<CardActions className={classes.chipBoard}>
    {deviceData.map(tag => tag.showOnLive ? makeLed(tag) : '')}
    {alarms && alarms.map(({ label, values }) => makeAlarmLed(label, values, deviceData))}
  </CardActions>);

  const siteOfflineStatusBar = (<CardActions className={classes.chipBoard}>
    <h5>Site offline</h5>
  </CardActions>);

  useEffect(() => {
    applyTagSpace(getSetup(), device.tags)
      .then((space) => setDeviceData(space))
      .catch((error) => console.log(error));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[device]);

  return (
    <Card className={device.status === 'ONLINE' ? classes.cardRootOnline : classes.cardRootOffline}>
      <CardActionArea
        onClick={() => navigateToDevice({ id: device._id, name: device.code })}>
        <CardContent>
          <Typography gutterBottom variant='h5' component='h2'>
            {device.code}
          </Typography>
        </CardContent>
        {device.status === 'ONLINE' ? siteOnlineStatusBar: siteOfflineStatusBar}
      </CardActionArea>
    </Card>
  );
}
