import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';

import { ReportsToolBar } from './reportsToolbar.component';
import { AnalyticsContext } from '../../analytics/context';
import { useDataGrid } from '../hooks';

export const Reports = () => {
  const {
    reportsData,
    analyticsState,
    dispatch,
    fileName: dynamicFileName,
    setSelectedTag,
  } = useDataGrid();

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport csvOptions={{ fileName: dynamicFileName }} />
      </GridToolbarContainer>
    );
  }

  return (
    <AnalyticsContext.Provider value={{ state: analyticsState, dispatch }}>
      <ReportsToolBar
        analyticsState={analyticsState}
        setSelectedTag={setSelectedTag}
        dispatch={dispatch}
      />
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          autoHeight
          {...reportsData}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    </AnalyticsContext.Provider>
  );
};
