import { StatusBoardAction, StatusBoardState } from '../types';

export const statusBoardReducer = (
  state: StatusBoardState,
  action: StatusBoardAction
): StatusBoardState => {
  switch (action.type) {
    case 'set-next-page':  return { 
        ...state,
        currentIndex: (++state.currentIndex) % state.devices.length

    };
    case 'set-previous-page':  return { 
        ...state,
        currentIndex: Math.abs(--state.currentIndex) % state.devices.length

    };
    case 'set-current-page':  return { 
        ...state,
        currentIndex: action.pageNumber
    };
    case 'set-slider-time':  return { 
        ...state,
        sliderTime: action.sliderTime
    };
    case 'refresh': return {
      ...state,
      refresh: !state.refresh,
      currentIndex: (++state.currentIndex) % state.devices.length
    }
    default:
      throw new Error();
  }
};
