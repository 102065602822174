import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { Device } from '../../devices/types';
import { DeviceStatus, DeviceAuthStatus } from '../../devices/enums';

const MapSize = 220;

type DeviceKanbanCardProps = {
  device: Device;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(3),
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cover: {
    width: MapSize,
  },
  cardRoot: {
    maxWidth: 345,
    marginBottom: theme.spacing(4),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  chipBoard: {
    justifyContent: 'center',
  },
}));

const MapMe = ({ location }: Device) => {
  // giving E-jssd office location as default
  const loc = location ?? { lat: 33.682942, lon: 73.0039036 };
  return (
    <div>
      <MapContainer
        center={[loc.lat, loc.lon]}
        zoom={13}
        scrollWheelZoom={false}
        style={{ height: MapSize }}>
        <TileLayer
          attribution='&copy; OpenStreetMap contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        <Marker position={[loc.lat, loc.lon]} />
      </MapContainer>
    </div>
  );
};

export function StatusCard({ device }: DeviceKanbanCardProps) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <div className={classes.cover}>
        <MapMe {...device} />
      </div>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography gutterBottom variant='h5' component='h2'>
            {device.code.toUpperCase()}
          </Typography>
          <Typography variant='body2' color='textSecondary' component='p'>
            {device.address}
          </Typography>
          <div className={classes.chips}>
            <Chip
              label={device.status}
              color={
                device.status === DeviceStatus.online ? 'primary' : 'secondary'
              }
            />
            <Chip
              label={device.authStatus}
              color={
                device.authStatus === DeviceAuthStatus.approved
                  ? 'default'
                  : 'secondary'
              }
            />
          </div>
        </CardContent>
      </div>
    </Card>
  );
}
