import { NavBarItem } from '../layout/types';
import { Device } from '../devices';
import { DeviceDashboard } from '../dashboard';
import { Reports } from '../reports';
import { Events } from '../events';
import { Alarms } from '../alarms';
import { Analytics } from '../analytics';
import { Scheduling } from '../schedules';
import HomeWorkOutlined from '@material-ui/icons/HomeWorkOutlined';
import Dashboard from '@material-ui/icons/Dashboard';
import BookOutlined from '@material-ui/icons/BookOutlined';
import ReportIcon from '@material-ui/icons/Description';
import AssessmentOutlined from '@material-ui/icons/AssessmentOutlined';
import NotificationsActiveOutlined from '@material-ui/icons/NotificationsActiveOutlined';
import ScheduleIcon from '@material-ui/icons/Schedule';

export const ApplicationConfig: NavBarItem[] = [
  {
    label: 'All Devices',
    to: '/',
    exact: true,
    icon: HomeWorkOutlined,
    component: Device,
    divider: true,
  },
  {
    label: 'Dashboard',
    to: '/dashboard/:devId/:devName',
    icon: Dashboard,
    component: DeviceDashboard,
    hidden: true,
  },
  {
    label: 'Reports',
    to: '/reports/:devId/:devName',
    icon: ReportIcon,
    component: Reports,
    hidden: true,
  },
  {
    label: 'Events',
    to: '/events/:devId/:devName',
    icon: BookOutlined,
    component: Events,
    hidden: true,
  },
  {
    label: 'Alarms',
    to: '/alarms/:devId/:devName',
    icon: NotificationsActiveOutlined,
    component: Alarms,
    hidden: true,
  },
  {
    label: 'Analytics',
    to: '/analytics/:devId/:devName',
    icon: AssessmentOutlined,
    component: Analytics,
    hidden: true,
  },
  {
    label: 'Scheduling',
    to: '/schedules/:devId/:devName',
    icon: ScheduleIcon,
    component: Scheduling,
    hidden: true,
  },
];
