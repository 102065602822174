/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import DateFnsUtils from '@date-io/date-fns';
import endOfDay  from 'date-fns/endOfDay';
import startOfDay  from 'date-fns/startOfDay';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import './events.css';
import { ErrorDiv, LoaderDiv } from '../../common';
import { useAuthRequest, getUserInfo } from '../../auth';
import { eventApi, GetEventsRequest } from '../../api';
import { useLayout, useLayoutDevice } from '../../layout';
import { Event } from '../types';
import { EventCard } from './event-card.component';
/**
 * @description Component renders the Device Events.
 * @output Renders an array of Device events
 * @objective To display new and past events
 */

export function Events(props: any) {
  const { setTitle, setDevice } = useLayout();
  const device = useLayoutDevice();
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [ request, response, error ] = useAuthRequest<GetEventsRequest>(
    (token, req) => {
      if (!req) throw new Error('Request Required');
      return eventApi.getEvents(req, token);
    }
  );
  useEffect(() => {
      setTitle(`${device.name} / Events`);
      setDevice(device);
      request();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device.name]);

  useEffect(() => {
    const { org } = getUserInfo();
    request({
     orgId: org,
     deviceId: device.id,
     etd: endOfDay(selectedDate!).getTime(),
     std: startOfDay(selectedDate!).getTime(),
     type: 'EVENT'
    });
  },[device.id, selectedDate]);

  const layout = (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='flex-start'
      spacing={4}
    >
      <Grid
        container
        item
        xs={11}
        spacing={2}
        justifyContent='flex-start'
        alignItems='center'
      >
        <Grid item>
          <h2>Select Date:</h2>
        </Grid>
        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id='date-picker-dialog'
              format='dd/MM/yyyy'
              value={selectedDate}
              onChange={(e) => setSelectedDate(e)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item>
        </Grid>
      </Grid>
      {response && (
        <>
          {
          response.json && response.json.length > 0
            ? response.json.map((event: Event, index: number) => {
                return <EventCard event={event} key={index} />;
              })
            : (<Grid item xs={11}><Paper elevation={3} style={{ padding: "1rem"}}>No Events</Paper></Grid>)
          }
        </>
      )}
    </Grid>);

  return (
    <>
    {
      error
        ? (<ErrorDiv />)
        : !response 
          ?  (<LoaderDiv />)
          : layout
    }
    </>
  )  
}
