import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

export const LoaderDiv = () => {
	const classes = useStyles();
	return (
	  <div className={classes.root}>
		<Skeleton />
		<Skeleton animation={false} />
		<Skeleton animation="wave" />
	  </div>
	);
};