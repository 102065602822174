import { useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { TimeSelect } from '.';
import { useSchedulesContext } from '..';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Alert from '@material-ui/lab/Alert';

/**
 * @description Component renders a form for adding a schedule for an output.
 * @output Renders a main schedule setting component with ADD and CANCEL buttons
 * @objective To setup/add new schedule
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formGrid:{
      margin: "3rem 0 3rem 0"
    },
    formButtons:{
      minWidth: "10rem",
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  }),
);

const getScheduleTimeString = (date: Date) => `${date.getHours() < 10 ? '0':''}${date.getHours()}:${date.getMinutes() < 10 ? '0':''}${date.getMinutes()}`;

export  function NewSchedule() {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState('');
  const { state, dispatch } = useSchedulesContext();
  const handleClose = () => { 
    setErrorMessage('');
    dispatch({type:'cancel-scheduler'});
  };
  const handleSave = () => {
    if(state.newStartTime > state.newEndTime) {
      return setErrorMessage('Start time is more then end time');
    }
    const start = getScheduleTimeString(state.newStartTime);
    const stop = getScheduleTimeString(state.newEndTime);
    if (state.schedule.some((value) => value.start >= start && value.start <= stop || value.stop >= start && value.stop <= stop)) {
      return setErrorMessage('Time Range in valid')
    }
    setErrorMessage('')
    dispatch({ type:'add-schedule', start, stop});
    dispatch({type:'cancel-scheduler'});
  }
  return (
    <Dialog open={state.addNew} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}>
      <DialogContent>
        <TimeSelect/>
        { errorMessage && <Alert severity="error">{errorMessage}</Alert> }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

