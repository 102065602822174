import { ReactNode } from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: "#036899",
    }
  },
});

export function ThemeProvider(props: {children?: ReactNode}) {
  return (
    <MuiThemeProvider theme={theme}>
      {props.children}
    </MuiThemeProvider>
  );
}
