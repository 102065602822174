/* eslint-disable react-hooks/exhaustive-deps */
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Analytic } from '../../automaton-tags';
import { roundOff, toKiloRepresentation } from '../../common';
import { useGraph } from '../hooks';

type Props = {
  height?: number;
  anaTag: Analytic & { tag: string };
  refresh?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    graphValueLabel: {
      marginLeft: theme.spacing(1.5),
    },
    barLabel: {
      fontSize: '0.7rem',
    },
    responsiveContainer: {
      paddingLeft: '2%',
    },
  })
);

export function Graph({ height = 500, anaTag, refresh }: Props) {
  const classes = useStyles();
  const {
    graphState: state,
    response,
    sum,
    average,
    xAxisLabels,
    xAxisLabelTimeHandler,
    yAxisLabelHandler,
  } = useGraph({ anaTag, refresh });

  function valueSwitch(anaType: string) {
    //this function determines whether total value(delta) or average value should be displayed in the graph under the graph header.
    switch (anaType.toLowerCase()) {
      case 'delta':
        return (
          <h4 className={classes.graphValueLabel}>
            Total {state.reqType.toLowerCase()} value: {roundOff(sum, 3)}
          </h4>
        );
      case 'average':
        return (
          <h4 className={classes.graphValueLabel}>
            Average {state.reqType.toLowerCase()} value: {roundOff(average, 3)}{' '}
          </h4>
        );
      default:
        return null;
    }
  }

  const barLabel = ({
    x,
    y,
    width,
    value,
  }: {
    x: number;
    y: number;
    width: number;
    height: number;
    value: number;
  }) => {
    return (
      <text
        className={classes.barLabel}
        x={x + width / 2}
        y={y}
        fill='#666'
        textAnchor='middle'
        dy={-12}>
        {toKiloRepresentation(value, 1)}
      </text>
    );
  };

  const graph = (
    <>
      {valueSwitch(anaTag.anaType)}
      <ResponsiveContainer
        width='96%'
        className={classes.responsiveContainer}
        height={400}>
        <BarChart
          margin={{ top: 40, right: 5, bottom: 40, left: 15 }}
          data={response?.json.logs}>
          <Bar dataKey='y' fill='#2596be' label={barLabel} />
          <XAxis
            tickFormatter={xAxisLabelTimeHandler}
            label={{
              value: xAxisLabels[state.reqType],
              position: 'insideCenter',
              dy: 20,
            }}
            dataKey='x'
          />
          <YAxis
            tickFormatter={yAxisLabelHandler}
            label={{
              value: anaTag.title,
              position: 'insideLeft',
              angle: -90,
              dx: -5,
            }}
            domain={anaTag.yAxisLimit}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );

  return <>{response ? graph : <Skeleton variant='rect' height={height} />}</>;
}
