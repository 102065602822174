import { useReducer } from "react";
import { Switch, Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { layoutReducer } from "../functions";
import { LayoutContext } from "../contexts";
import { ApplicationConfig } from "../../configs";
import { SideNavBar } from "./side-nav-bar.component";
import { TopNavBar } from "./top-nav-bar.component";
import { ThemeProvider } from "./with-theme.component";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
}));

export const LayoutProvider = () => {
  const [state, dispatch] = useReducer(layoutReducer, {
    title: "Reflow",
    openDeviceNav: false,
    device: null,
  });
  const classes = useStyles();
  return (
    <LayoutContext.Provider value={{ state, dispatch }}>
      <div className={classes.root}>
        <CssBaseline />
        <ThemeProvider>
          <TopNavBar />
          <SideNavBar items={ApplicationConfig} />
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Switch>
              {ApplicationConfig.map(({ to, component, exact }) => (
                <Route path={to} component={component} key={to} exact={Boolean(exact)} />
              ))}
            </Switch>
          </main>
        </ThemeProvider>
      </div>
    </LayoutContext.Provider>
  );
};
