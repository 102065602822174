import { useEffect } from 'react';
import { useRequest } from '../../common';
import { authApi } from '../../api';
import { AjaxResponse } from '../../lib/ajax/types';
import { useAuthContext } from '../hooks/use-auth-context.hook';
import { 
    getRefreshToken,
    getUserInfo,
    saveAuthResponse,
    clearAuthSetup
} from '../functions';

export const useRelogin = ():[() => void, AjaxResponse | null, Error | null] => {
    const { dispatch } = useAuthContext();
    const [request, response, error] = useRequest( 
        (data) => authApi.exchangeToken(data as string)
    );  
    useEffect(() => {     
        if (response) {
            saveAuthResponse(
                getUserInfo().username,
                response.json
            );        
            dispatch({ type: 'login' });
       }
        else if (error) {
            clearAuthSetup();        
            dispatch({ type: 'logout'});
        }
    }, [response, error, dispatch]);

    return [
        () => {
            request(
                getRefreshToken()
            );
        },
        response,
        error
    ]
};
