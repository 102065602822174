import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useAutomatonTags } from '../../automaton-tags';
import { useSchedulesContext } from '..';

/**
 * @description Component renders an element for selecting output type.
 * @output Renders a MUI Select(dropdown) for selecting output type either "Lamp" or "Motor"
 * @objective To set output type in state
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 200,
    },
    inputLabel:{
      marginRight: theme.spacing(2),
      color: "#036899",
      fontWeight:'bold'  
    },
    dropdownSelectIcon:{
      "& .MuiSvgIcon-root": {
      color: "#036899",
    },
    },
  }),
);
export  function DeviceSelect() {
  const classes = useStyles();
  const { state, dispatch } = useSchedulesContext();
  const { getSetup } = useAutomatonTags();
  const setup = getSetup();
  const menu = Object.keys(setup).map((key) => (setup[key].ioType === 'output' ?
      (<MenuItem value={key} key={key}>{key}</MenuItem>): null) 
  );

  return (
    <Grid container item lg={3} md={4} xs={12} direction="row" alignItems="center" >
      <span className={classes.inputLabel}>Device</span>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          margin="none"
          id="demo-simple-select-outlined"
          value={''}
          onChange={(e)=>{ }}
          displayEmpty
          className={classes.dropdownSelectIcon}
        >
          <MenuItem disabled value="">
            <em>None</em>
          </MenuItem>
          {menu}
        </Select>
      </FormControl>
    </Grid>
  )
}
