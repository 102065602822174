import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { IconButton } from '@material-ui/core';
import { useSchedulesContext } from '../hooks/use-schedules-context.function';

const useStyles =  makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableHeadCell:{
    backgroundColor: "#036899",
    color: theme.palette.common.white,
    [theme.breakpoints.up('lg')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
  },
  tableBodyCell:{
    [theme.breakpoints.up('lg')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
  },
  tableIcon:{
    color: "#02537a"
  }
}));

export  function ScheduleView() {
  const classes = useStyles();
  const { state, dispatch  } = useSchedulesContext();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead >
          <TableRow >
            <TableCell className={classes.tableHeadCell} align="center">Start Time</TableCell>
            <TableCell className={classes.tableHeadCell} align="center">End Time</TableCell>
            <TableCell className={classes.tableHeadCell} align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state.schedule.map((row, index) => (
            <TableRow key={index}>
              <TableCell className={classes.tableBodyCell} align="center">{row.start}</TableCell>
              <TableCell className={classes.tableBodyCell} align="center">{row.stop}</TableCell>
              <TableCell className={classes.tableBodyCell} align="center">
                {/*<IconButton>
                  <LineStyleIcon className={classes.tableIcon}/>
          </IconButton >*/}
                <IconButton onClick={()=>{
                  dispatch({ type: 'delete-schedule', index })
                }}>
                  <DeleteOutlineIcon className={classes.tableIcon}/>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}