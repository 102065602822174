import { LayoutAction, LayoutState } from '../types';

export const layoutReducer = (
  state: LayoutState,
  action: LayoutAction
): LayoutState => {
  switch (action.type) {
    case 'set-title': return {
      ...state,
      title: action.title
    }
    case 'open-device-nav': return {
      ...state,
      openDeviceNav: action.open
    }
    case 'set-device': return {
      ...state,
      device: action.device
    }
    default:
      throw new Error();
  }
};
