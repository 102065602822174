import { useHistory } from 'react-router-dom';
import { NavDeviceInfo } from '../types';
import { useLayout } from '../hooks';

export const useLayoutNav = () => {
    const history = useHistory();
    const { setDevice } = useLayout();
    return (device: NavDeviceInfo) => {
        setDevice(device);
        return history.push(`/dashboard/${device.id}/${device.name}`);
    }
}
