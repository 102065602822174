/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { deviceApi } from '../../api';
import { useAuthRequest } from '../../auth';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { Device } from '../../devices/types';
import { useAutomatonTags, applyTagSpace } from '../../automaton-tags';
import { Card } from '@material-ui/core';
import { useStatusBoardContext } from '../hooks';
import { roundOff } from '../../common';

type DeviceStatusCardProps = { device: Device };

const keepStringRoundOffNumber = (value: string) => {
  if (Number.isNaN(parseInt(value))) return value;
  else return roundOff(parseFloat(value), 3);
};

const TagItem = ({ tag, index }: any) => (
  <>
    {index === 0 ? null : <Divider />}
    <ListItem>
      <ListItemAvatar>
        <Avatar src={tag.image}></Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={tag.label}
        secondary={keepStringRoundOffNumber(tag.value)}
      />
    </ListItem>
  </>
);

export const DeviceStatusCard = ({ device }: DeviceStatusCardProps) => {
  const {
    state: { refresh },
  } = useStatusBoardContext();
  const [deviceData, setDeviceData] = useState<any[]>([]);
  const { getSetup } = useAutomatonTags();

  const [request, response] = useAuthRequest((token) =>
    deviceApi.getRealTimeData(device._id, token)
  );

  useEffect(() => {
    request();
  }, [refresh]);

  useEffect(() => {
    if (response) {
      applyTagSpace(getSetup(), response.json)
        .then((space) => setDeviceData(space))
        .catch((error) => console.log(error));
    }
  }, [response]);

  return (
    <Card>
      <List component='nav'>
        {deviceData &&
          deviceData.map((tag, index) => <TagItem tag={tag} key={index} index={index} />)}
      </List>
    </Card>
  );
};
