import { useReducer, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Analytic } from "../../automaton-tags";
import { AnalyticsContext } from "../context";
import { analyticsReducer } from "../functions";
import { LoggingRequestTypes } from "../enums";
import { GraphToolBar } from "./graph-tool-bar.component";
import { Graph } from "./graph.component";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginBottom: theme.spacing(3),
    },
    action: {
      justifyContent: "center",
    },
  })
);
export const GraphCard = (props: {
  deviceId: string;
  graphHeight?: number;
  anaTag: Analytic & { tag: string };
  refresh?: boolean;
}) => {
  const classes = useStyles();
  const [state, dispatch] = useReducer(analyticsReducer, {
    selectedDate: new Date(),
    reqType: LoggingRequestTypes.daily,
    deviceId: props.deviceId,
  });

  useEffect(() => {
    dispatch({ type: 'set-device-Id', deviceId: props.deviceId});
  }, [props.refresh, props.deviceId]);

  return (
    <AnalyticsContext.Provider value={{ state, dispatch }}>
      <Card className={classes.root}>
        <CardHeader
          avatar={<Avatar aria-label="tag">{props.anaTag.avatar}</Avatar>}
          action={
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          }
          title={props.anaTag.title}
          subheader={props.anaTag.subHeader}
        />
        <Graph anaTag={props.anaTag} height={props.graphHeight} refresh={props.refresh}/>
        <CardContent></CardContent>
        <CardActions id="hideFullscreenToolbar" className={classes.action}>
          <GraphToolBar />
        </CardActions>
      </Card>
    </AnalyticsContext.Provider>
  );
};
