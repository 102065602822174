import { KeyboardTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import TimerIcon from '@material-ui/icons/Timer';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useSchedulesContext } from '..';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

/**
 * @description Component renders an element for selecting time.
 * @output Renders a MUI TimePicker component for selecting "Start Time" and "End Time"
 * @objective To set start and end times in state
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timeControl: {
      maxWidth: 200,
    },
    inputLabel:{
      marginRight: theme.spacing(2),
      color: "#036899",
      fontWeight:'bold'  
    },
    inputIcon:{
      color: "#036899",
    },
  }),
);

const getTimeString = (date?: MaterialUiPickersDate): Date => {
  if(!date) throw new Error('date not defined here')
  return date;
  // return `${date.getHours() > 10 ? '':'0'}${date?.getHours()}:${date.getMinutes() > 10 ? '':'0'}${date?.getMinutes()}`
}

export  function TimeSelect() {
  const classes = useStyles();
  const { state, dispatch } = useSchedulesContext();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} >
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={4}
      > 
        <Grid item >
          <span  className={classes.inputLabel}>Start Time</span>
          <KeyboardTimePicker
            autoOk
            ampm={false}
            className={classes.timeControl}
            inputVariant="outlined"
            id="start-time-picker"
            value={state.newStartTime}
            onChange={(date) => {
              dispatch({type: 'set-new-start-time', value: getTimeString(date)})
            }}
            KeyboardButtonProps={{
                'aria-label': 'change start time',
            }}
            keyboardIcon={<TimerIcon className={classes.inputIcon}/>}
          />
        </Grid>
        <Grid item >
          <span  className={classes.inputLabel}>End Time</span>
          <KeyboardTimePicker
            autoOk
            ampm={false}
            className={classes.timeControl}
            inputVariant="outlined"
            id="end-time-picker"
            value={state.newEndTime}
            onChange={(date) => {
              dispatch({type: 'set-new-end-time', value: getTimeString(date)})
            }}
            KeyboardButtonProps={{
                'aria-label': 'change end time',
            }}
            keyboardIcon={<TimerOffIcon className={classes.inputIcon}/>}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  )
}
