/**
 * # HTML Content
 * Parses HTML written in text form
 *
 */

import React from "react";

type HTMLContentProps = JSX.IntrinsicElements["p"] & {
  children: string;
};

export const HTMLContent: React.FC<HTMLContentProps> = ({ children, ...restProps }) => {
  // remove escaped line breaks in translations
  if (children) {
    const html = children.replace(/\\n/g, "");
    // TODO: check for universal rendering supported solution
    const content = html;

    return (
      <p
        dangerouslySetInnerHTML={{
          __html: content,
        }}
        {...restProps}
      />
    );
  }
  return null;
};
