import { Grid, Typography } from '@material-ui/core';
import './alarms.css';
import { toTime, toDate, toTimeElapsed } from '../../common';
import { Alarm } from '../types';

export function AlarmCard(props: {alarm: Alarm}) {
  const { tag, code, message, onsetAt } = props.alarm;
  const onsetAtDate = new Date(onsetAt);
  return (
    <>
      <Grid
        container
        item
        xs={11}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        className='alarm-grid'
      >
        <Grid item xs={12}>
          <Grid item xs={12} sm container className='alarm-card' spacing={2}>
            <Grid item xs container direction='column'>
              <Grid item xs>
                <Typography style={{color: "#0a4a78"}} gutterBottom>
                  {tag}
                </Typography>
                <Typography style={{color: "#036899"}} gutterBottom variant='body2'>
                  {code}
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                  {message}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant='subtitle1'
                align='right'
                color='textSecondary'
              >
                {toDate(onsetAtDate)}
              </Typography>
              <Typography
                gutterBottom
                align='right'
                variant='subtitle1'
                color='textSecondary'
              >
                {toTime(onsetAtDate)}
              </Typography>
              <Typography variant='body2' color='textSecondary' align='right'>
                {toTimeElapsed(onsetAtDate)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
