import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { getUserInfo } from '../../auth';
import pcrwrLogoImage from "../../common/logos/pcrwr-logo-white.png";
import govPakLogoImage from "../../common/logos/gov-pakistan-white.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      width: '100%',
      backgroundColor: ' #036899',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        display: "none"
      },
    },
    title: {
      flexGrow: 1,
      color: 'white',
      fontSize: "40px",
      [theme.breakpoints.down('sm')]: {
        fontSize: "30px",
      },
    },
    pcrwrLogo: {
      height: "50px",
      maxWidth: "50px",
      [theme.breakpoints.down('sm')]: {
        fontSize: "30px",
      },
    },
  })
);

export function TitleHeader() {
  const classes = useStyles();
  const { org } = getUserInfo();
  return (
    <AppBar className={classes.appBar} color='inherit'>
      <Toolbar>
        {org === "PCRWR" && <img className={classes.pcrwrLogo} src={pcrwrLogoImage} alt="PCRWR logo"/>}
        <Typography variant='h6'  className={classes.title}>
        {org.toUpperCase()} Filtration Plant - Water Quality Monitoring
        </Typography>
        {org === "PCRWR" && <img className={classes.pcrwrLogo} src={govPakLogoImage} alt="Gov. of Pakistan logo"/> }
      </Toolbar>
    </AppBar>
  );
}
