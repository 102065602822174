import { Engine } from 'json-rules-engine';
import { DeviceStatus } from '../../devices/enums/device-status.enum';
import { AppliedSpace, TagData, TagSetup } from '../types';
import { NoTagData } from '../errors';

type TagDataCache = Record<string, TagData>;

const getAppliedSpaceValue = (setup: TagSetup, tag: TagData, value: string): AppliedSpace => ({
    type: setup.type === 'C' ? 'D' : setup.type, // TODO: making type coputation in to digital for now can change later
    label: setup.label || tag.name,
    value,
    status: tag.status,
    image: setup.image,
    sortOrder: setup.sortOrder,
    alarm: tag.alarm,
    event: tag.event,
    showOnLive: setup.showOnLive,
    ledCreator: setup.ledCreator
})

// as currently for some silly reason i chose to send unit :( along the value :( BAD
const extractValue = (value: string): number => +value.split(' ')[0]

const extractTagsStatus = (tags: TagData[]): string => {
    const anyOfflineTag = tags.some((tag) => tag.status === DeviceStatus.offline || tag.status === DeviceStatus.error)
    if (anyOfflineTag) return DeviceStatus.offline;
    return DeviceStatus.online;
}

const applyDigital = (setup: TagSetup, data: TagData): AppliedSpace => {
    const { value } = data;
    let curVal ='';
    if (value) {       
        curVal = extractValue(value) > 0 
            ?  setup.onText || 'On'
            :  setup.offText || 'Off' 
    }
    return getAppliedSpaceValue(setup, data, curVal);
}

const applyComputation = async (setup: TagSetup, tags: TagData[], tagName: string): Promise<AppliedSpace> => { 
    const { computation } = setup;
    const requireTags = tags.filter((t) => (setup.requiredTags ? setup.requiredTags.includes(t.name) : false));
    const compTag: TagData = {
        name: setup.label || tagName,
        status: extractTagsStatus(requireTags),
        value: '',
    } 
    const facts = tags.reduce((acc, tag) => ({ ...acc, [tag.name]: extractValue(tag.value ?? '')}), {})
    if (!computation) return getAppliedSpaceValue(setup, compTag, '');
    const engine = new Engine(computation, { allowUndefinedFacts: false });
    const { events } = await engine.run(facts).catch(error=> { console.log('error', error); return {events: []}});
    const [event] = events; // make sure two events are not fired other wise the first one will be picked up
    if (event) return getAppliedSpaceValue(setup, compTag, event.type);
    return getAppliedSpaceValue(setup, compTag, '');
}

export const applyTagSpace = async (setup: Record<string, TagSetup>, data: TagData[]): Promise<AppliedSpace[]> => {
    // converting array of tags to and object of tags {}
    const cache = data.reduce<TagDataCache>((acc, tag) => ({ ...acc, [tag.name]: tag}), {});
    const setupKeys = Object.keys(setup);
    // we have to use promises as computation tags use rule engine which is async 
    const promises = setupKeys.map(async (key) => {
        if (cache[key] === undefined && setup[key].type !== 'C') throw new NoTagData();
        switch(setup[key].type) {
            case 'A': return getAppliedSpaceValue(setup[key], cache[key], cache[key] ? cache[key].value || '' : '');
            case 'C': return await applyComputation(setup[key], data, key);
            case 'D': return applyDigital(setup[key], cache[key]);
            default: throw new Error('In correct configuration');
        }
    });
    const results = await Promise.allSettled(promises);
    return results.reduce<AppliedSpace[]>((acc, cur, index) => {
        if(cur.status === 'fulfilled') {
            acc.push(cur.value);
        } else {
            if (cur.reason instanceof NoTagData) return acc;
            acc.push(
                getAppliedSpaceValue(
                    setup[setupKeys[index]],
                    { name: setupKeys[index], status: DeviceStatus.error}, 
                    'Internal Error'
                )
            );
        }
        return acc;
    }, []).sort((a, b) => (a.sortOrder - b.sortOrder)); // sorting in sort order so that display is consistent    
}