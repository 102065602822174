import { useAuthContext } from './use-auth-context.hook';
import { clearAuthSetup } from '../functions';

export const useLogout = () => {
    const { state, dispatch } = useAuthContext();  
    return { 
      state,
      logout: () => {
        clearAuthSetup();
        dispatch({ type: 'logout'});
      }
    };
  }