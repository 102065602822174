type Props = {
    heading?: string;
    body?: string;
}
export const ErrorDiv = ({ heading = 'An error has occured', body = 'Please refresh page'}: Props) => (
	<div>
		<h4>{heading}</h4>
		<hr />
		<p>{body}</p>
	</div>
);