import { LoginState, LoginAction } from '../types';

export const loginReducer = (
  state: LoginState,
  action: LoginAction
): LoginState => {
  switch (action.type) {
    case 'login':
      return {
        loggedIn: true
      };
    case 'logout':
      return {
        loggedIn: false
      };
    default:
      throw new Error();
  }
};
