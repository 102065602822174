/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useEffect } from "react";
import { automatonTagsReducer } from "../functions";
import { AutomatonTagsContext } from "../contexts";
import { useRequest, LoaderDiv } from "../../common";
import { getUserInfo } from "../../auth";
import { get } from "../../lib";

export const AutomatonTagsProvider = ({ children }: { children: React.ReactNode }) => {
  const { org } = getUserInfo();
  const [state, dispatch] = useReducer(automatonTagsReducer, {
    orgId: org,
    fetching: true,
    tagSetup: {},
    profileSetup: {
      alarmSound: false,
      startAt: "",
      customComponents: {
        header: {
          visibility: false,
          text: "",
        },
      },
      customStyles: {
        customColors: {},
      },
      customLogos: {}
    },
  });
  const [request, response, error] = useRequest(() => get(`org-setup/${org}/automaton-tags.json`));

  useEffect(() => {
    if (Object.keys(state.tagSetup).length === 0) {
      request();
      dispatch({ type: "fetching" });
    }
  }, [org]);

  useEffect(() => {
    if (response) {
      try {
        const { Automaton_Tags } = JSON.parse(response.text);
        const { Profile_Config } = JSON.parse(response.text);
        dispatch({ type: "set-automaton-tags", tagSetup: Automaton_Tags });
        dispatch({ type: "set-profile-tags", profileSetup: Profile_Config });
      } catch (error) {
        dispatch({ type: "set-automaton-tags", tagSetup: {} });
      }
    }
    if (error) {
      dispatch({ type: "set-automaton-tags", tagSetup: {} });
    }
  }, [response, error]);

  return (
    <AutomatonTagsContext.Provider value={{ state, dispatch }}>
      {state.fetching ? <LoaderDiv /> : children}
    </AutomatonTagsContext.Provider>
  );
};
