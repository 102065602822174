import { post } from "../lib";

const { REACT_APP_API_URL } = process.env;

export type GetEventsRequest = {
    orgId: string;
    deviceId: string;
    type: 'EVENT' | 'ALARM';
    std: number;
    etd: number;
  }

export const eventApi = {
	getEvents: (request: GetEventsRequest,token: string | null) => post(`${REACT_APP_API_URL}/events/list`, request, token || '')
};