import { makeStyles } from "@material-ui/core/styles";
import { Grid, Toolbar, Typography } from "@material-ui/core";
import { HTMLContent } from "../../common";

type HeaderProps = {
  text: string;
  logos?: Record<string, any>;
};

const useStyles = makeStyles((theme) => ({
  StatusboardHeader: {
    textAlign: "center",
    color: "white",
    margin: "0.5rem",
    display: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  logoStyles: {
    height: "4rem",
    [theme.breakpoints.down("sm")]: {
      height: "3rem",
    },
  },
  title: {
    flexGrow: 1,
    color: "#036899",
    fontSize: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "4rem",
    },
  },
}));

export const FullscreenHeader: React.FC<HeaderProps> = ({ text, logos }) => {
  const classes = useStyles();

  return (
    <Grid id="fullscreenHeader" container className={classes.StatusboardHeader} direction="row">
      <Toolbar>
        <img className={classes.logoStyles} src={logos?.pcrwr} alt="PCRWR logo" />
        <Typography className={classes.title}>
          <HTMLContent>{text}</HTMLContent>
        </Typography>
        <img
          className={classes.logoStyles}
          src={logos?.gov_of_pakistan}
          alt="Gov. of Pakistan logo"
        />
      </Toolbar>
    </Grid>
  );
};
