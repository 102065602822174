import { useState, useEffect } from 'react';
import { AjaxResponse } from '../../lib/ajax/types';
import { UseRequest } from '../types';

export const useRequest: UseRequest = (request) => {
	const [response, setResponse] = useState<AjaxResponse | null>(null);
	const [error, setError] = useState<any | null>(null);
  	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState<any | null>(undefined);

	useEffect(() => {
    	if (isLoading === false) return;
		const { promise, cancel } = request(data);
		promise
			.then(resp => {
				setResponse(resp)
				setError(null);
			})
			.catch(err => {
				if (err.isCanceled) return;
				setResponse(null);
				setError(err);
			})
      		.finally(() => setIsLoading(false));
		return () => cancel();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);
	return [
		(data) => {
			setData(data);
			setIsLoading(true);
		},
		response,
		error,
		isLoading	
	];
};
