import { useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { AsyncLoader } from "../../common";
import { deviceApi } from "../../api";
import { useAuthRequest } from '../../auth';
import { useLayout } from "../../layout";
import { StatusBoard } from "../../status-board";
import { DeviceKanban } from "./device-kanban.component";
import { DeviceSummary } from './device-summary.component';
import { DeviceLive } from './device-live.component';
import IconButton from "@material-ui/core/IconButton";
import TableChartIcon from "@material-ui/icons/TableChart";
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import AppsIcon from "@material-ui/icons/Apps";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import { openFullscreen } from "../../status-board/functions";
import { useAutomatonTagsContext } from '../../automaton-tags/hooks/use-automaton-tags-context.hook';


export const Device = () => {
  const divElem = useRef<HTMLDivElement>(null);
  const { setTitle, setDevice } = useLayout();
  const { state } = useAutomatonTagsContext();
  const [view, setView] = useState(state.profileSetup.startAt || 'table');
  const [ request, response, error ] = useAuthRequest(
    (token) => deviceApi.allDevices(token)
  );
  
  useEffect(() => {
    setTitle("Devices");
    setDevice(null);
    request();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid container spacing={3}>
      <Grid item xs container justifyContent="space-between" >
        <ButtonGroup variant="contained" color="primary">
          <IconButton
            color="primary"
            component="span"
            onClick={() => setView("table")}
          >
            <TableChartIcon />
          </IconButton>
          <IconButton
            color="primary"
            component="span"
            onClick={() => setView("kanban")}
          >
            <AppsIcon />
          </IconButton>
          <IconButton
            color="primary"
            component="span"
            onClick={() => setView("summary")}
          >
            <AccountBalanceWalletIcon />
          </IconButton>
          <IconButton
            color="primary"
            component="span"
            onClick={() => setView("live")}
          >
            <AcUnitIcon />
          </IconButton>
        </ButtonGroup>
        <ButtonGroup variant="contained" color="primary">
          <IconButton 
            color="primary"
            component="span"
            onClick={() => openFullscreen(divElem.current)}
          >
            <SettingsOverscanIcon />
          </IconButton>
        </ButtonGroup>
      </Grid>
      <Grid ref={divElem} item xs={12}>
        <AsyncLoader
          response={response}
          error={error}                
          render={(resp)=>{
              if (view === 'table') return (<StatusBoard devices={resp.json}/>)
              if (view === 'kanban') return (<DeviceKanban devices={resp.json}/>)
              if (view === 'summary') return (<DeviceSummary devices={resp.json} alarmSound={state.profileSetup.alarmSound}/>)
              if (view === 'live') return (<DeviceLive devices={resp.json}/>)
          }}
        />
      </Grid>
    </Grid>
  );
};
