import { useState, useEffect } from 'react';
import { AjaxResponse } from '../../lib/ajax/types';
import { UseAuthRequest } from '../types';
import { useRelogin } from './use-relogin.hook';
import { getAccessToken } from '../functions';

export const useAuthRequest: UseAuthRequest = (request) => {
	const [response, setResponse] = useState<AjaxResponse | null>(null);
	const [error, setError] = useState<any | null>(null);
  	const [isLoading, setIsLoading] = useState(false);
	const [send, setSend] = useState(false);
	const [data, setData] = useState<any | undefined>(undefined);
	const [relogin, relogResp, relogError] = useRelogin();

	useEffect(() => {
	   	if (send === false) return;
		const accessToken  = getAccessToken();
		const { promise, cancel } = request(accessToken, data);
		promise
			.then(resp => {
				setIsLoading(false);
				setSend(false);
				setResponse(resp);
				setError(null);
			})
			.catch(err => {
				if (err.isCanceled) return;
                if (err.status === 401) {
					// used to early exist this useEffect and reset to true if reloged in a success
					setSend(false); 
					return relogin();
				}
				setIsLoading(false);
				setSend(false);
				setResponse(null);
				setError(err);
			});
		return () => cancel();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [send]);

	useEffect(() => {
		if (relogResp) {
			setSend(true); // retrigger request send 
		} else if(relogError) {
			setError(relogError)
		}
	}, [relogResp, relogError]);

	return [
		(data) => {
			setData(data);
			setSend(true);
			setIsLoading(true);
		},
		response,
		error,
		isLoading	
	];
}