/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useLayout, useLayoutDevice } from '../../layout';
import { useAutomatonTags } from '../../automaton-tags';
import { GraphCard } from './graph-card.component';
import { getAnalyticalTags } from '../functions';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    }
  })
);

export function Analytics() {
  const classes = useStyles();
  const { setTitle, setDevice } = useLayout();
  const device = useLayoutDevice();
  const { state: { tagSetup } } = useAutomatonTags();
  
  useEffect(() => {
      setTitle(`${device.name} / Analytics`);
      setDevice(device);
  }, [device.name]);

  return (
    <Grid
      container
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      spacing={1}
      className={classes.root}
    >
     {
       getAnalyticalTags(tagSetup).map(tag => (<GraphCard anaTag={tag} key={tag.tag} deviceId={device.id}/>))
     }
    </Grid>
  );
}
