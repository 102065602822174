export function toTime(timestamp: Date) {
  let hours = timestamp.getHours();
  let minutes = timestamp.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  return `${hours}:${minutes < 10 ? `0${minutes}`: minutes}:${ampm}`;
}

export function toDate(timestamp: Date) {
  let date, month, year;
  date = timestamp.getDate();
  year = timestamp.getFullYear();
  month = timestamp.toLocaleString('default', { month: 'long' });
  let strDate = date + ' ' + month + ', ' + year;
  return strDate;
}

export function toTimeElapsed(timestamp: Date) {
  let endTime = new Date();
  let timeDiff = endTime.getTime() - timestamp.getTime();
  timeDiff = timeDiff / 1000;
  timeDiff = Math.floor(timeDiff / 60);
  let minutes = timeDiff % 60;
  let minutesAsString = minutes < 10 ? '0' + minutes : minutes;
  timeDiff = Math.floor(timeDiff / 60);
  let hours = timeDiff % 24;
  timeDiff = Math.floor(timeDiff / 24);
  let days = timeDiff;
  let totalHours = hours + days * 24; // add days to hours
  let totalHoursAsString = totalHours < 10 ? '0' + totalHours : totalHours;
  if (totalHoursAsString === '00') {
    return minutesAsString + ' minutes ago';
  } else {
    return days + ' days ago';
  }
}
