import { useEffect, useState } from 'react';
import { useAuthContext } from './use-auth-context.hook';
import { LoginFormField } from '../types';
import { useRequest } from '../../common/hooks'
import { authApi } from '../../api/auth.api';
import { setUserInfo, setRefreshToken, setAccessToken, clearAuthSetup } from '../functions'

export const useLogin = () => {
    const { dispatch } = useAuthContext();  
    const [ values, setValues ] = useState<LoginFormField>({
      org: '',
      username: '',
      password: ''
    });
    const [request, response, error , isLoggingIn ] = useRequest(
      () => authApi.login(values.org, values.username, values.password)
    );
    useEffect(() =>{
      if (response) {     
        const { context: { uid, role, org }, accessToken, refreshToken } = response.json;
        const payload =  {
          username:  values.username,
          userId: uid,
          userRole: role,
          org,
        }        
        setUserInfo(payload);
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
        dispatch({
          type: 'login',
        });
      }
      else if (error) {
        clearAuthSetup();
        dispatch({ type: 'logout'});
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response, error]);
    return { response, error, isLoggingIn, login: (values: LoginFormField) => { 
        setValues(values) 
        request();
      }
    };
  }

