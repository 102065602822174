import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { useLayoutNav } from '../../layout';
import { Device } from '../types';
import { DeviceStatus, DeviceAuthStatus } from '../enums';

type DeviceKanbanCardProps = {
  device: Device;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      maxWidth: 345,
      marginBottom: theme.spacing(4),
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    chipBoard: {
      justifyContent: 'center',
    },
  })
);

const MapMe = ({ location }: Device) => {
  // giving E-jssd office location as default
  const loc = location ?? { lat: 33.682942, lon: 73.0039036 };
  return (
    <div className='leaflet-container'>
      <MapContainer
        center={[loc.lat, loc.lon]}
        zoom={13}
        scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; OpenStreetMap contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        <Marker position={[loc.lat, loc.lon]} />
      </MapContainer>
    </div>
  );
};

export function DeviceKanbanCard({ device }: DeviceKanbanCardProps) {
  const classes = useStyles();
  const navigateToDevice = useLayoutNav();
  return (
    <Card className={classes.cardRoot}>
      <MapMe {...device} />
      <CardActionArea
        onClick={() => navigateToDevice({ id: device._id, name: device.code })}>
        <CardContent>
          <Typography gutterBottom variant='h5' component='h2'>
            {device.code}
          </Typography>
          <Typography variant='body2' color='textSecondary' component='p'>
            {device.address}
          </Typography>
        </CardContent>
        <CardActions className={classes.chipBoard}>
          <div className={classes.chips}>
            <Chip
              label={device.status}
              color={
                device.status === DeviceStatus.online ? 'primary' : 'secondary'
              }
            />
            <Chip
              label={device.authStatus}
              color={
                device.authStatus === DeviceAuthStatus.approved
                  ? 'default'
                  : 'secondary'
              }
            />
          </div>
        </CardActions>
      </CardActionArea>
    </Card>
  );
}
