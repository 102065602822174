import { post } from "../lib";

const { REACT_APP_API_URL } = process.env;

export type GetLogsRequest = {
    deviceId: string;
    tag: string;
    reqType: 'YEARLY' | 'MONTHLY' | 'DAILY';
    year: number;
    month?: number;
    day?: number;
  }

export const logsApi = {
	getLogs: (request: GetLogsRequest,token: string | null) => post(`${REACT_APP_API_URL}/logs/list`, request, token || '')
};