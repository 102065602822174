import { createElement, Fragment } from 'react';
import { DrawerProps } from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useTranslation } from '../../translation';
import { NavLink } from 'react-router-dom';
import { useLayoutContext } from '../hooks';
import { NavBarItem, LayoutState } from '../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoContainer: {
      backgroundColor: '#0a4a78',
    },
    logoText: {
      color: 'white',
      fontWeight: 'bolder',
      fontSize: '25px',
    },
    logoGrid: {
      padding: '0.7rem',
    }
  }),
);

const createPath = (path: string, state: LayoutState) => path
  .replace(':devId', state.device ? state.device.id : ' ')
  .replace(':devName', state.device ? state.device.name : ' ')

export function SideNavDrawer(props: { items: NavBarItem[] } & DrawerProps) {
  const { items, ...drawerProps } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { state } = useLayoutContext();
  const itemList = items.map(({to, label, icon, divider, hidden }) => (
    hidden && state.device === null
      ? null
      : (<Fragment key={label}>
        <NavLink to={createPath(to, state)} style={{ textDecoration: 'none', color: 'inherit' }}>
          <ListItem button key={label}>
            <ListItemIcon children={createElement(icon, {style: { color: 'white' }})}/>
            <ListItemText primary={t(label)} />
          </ListItem>
        </NavLink>
        { divider ? (<Divider />) : null}
      </Fragment>)
  ))

  return (
    <div>
    <Drawer {...drawerProps} >      
      <div className={classes.logoContainer}>
        <Grid 
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          className={classes.logoGrid}
          spacing={1}>
          <Grid item>
            <img src="images/logo.png" alt="R" />
          </Grid>
          <Grid item>
            <div className={classes.logoText}>REFLOW</div>
          </Grid>
        </Grid>
      </div>
      <Divider />
      <List>
        {itemList}
      </List>
    </Drawer>
    </div>
  );
}
