import { useReducer } from 'react';
import { Device } from '../../devices/types';
import { StatusCard } from './status-card.component';
import { DeviceStatusCard } from './device-status-card.component';
import { DeviceAnalyticsCard } from './device-analytics-card.component';
import { StatusBoardContext } from '../contexts';
import { statusBoardReducer } from '../functions';
import { Grid } from '@material-ui/core';
import { FullscreenHeader } from './fullscreen-header.component';
import { useAutomatonTagsContext } from '../../automaton-tags/hooks/use-automaton-tags-context.hook';

type StatusBoardProps = {
  devices: Device[];
};

export function StatusBoard({ devices }: StatusBoardProps) {

  const [state, dispatch] = useReducer(statusBoardReducer, {
    currentIndex: 0,
    sliderTime: 10 * 1000,
    devices: devices.sort((a,b) => a.code > b.code ? 1 : -1),
    refresh: false,
  });
  const { state: autamatonState } = useAutomatonTagsContext();
  const { header } = autamatonState.profileSetup.customComponents;
  const { customLogos } = autamatonState.profileSetup;

  return (
    <StatusBoardContext.Provider value={{ state, dispatch }}>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='flex-start'
        spacing={2}>
        {header.visibility && (
          <FullscreenHeader text={header.text} logos={customLogos} />
        )}
        <Grid item lg={3} md={4} xs={12}>
          <StatusCard device={devices[state.currentIndex]} />
          <DeviceStatusCard device={devices[state.currentIndex]} />
        </Grid>
        <Grid item lg={9} md={8} xs={12}>
          <DeviceAnalyticsCard
            dispatch={dispatch}
            device={devices[state.currentIndex]}
          />
        </Grid>
      </Grid>
    </StatusBoardContext.Provider>
  );
}
