import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
/**
 * @description Component sends Reset-Password link to user's email.
 * @output Renders one input field that takes in email,
 * @objective saves email to state, and calls back-end API with data(email) for user Forgot-Password that sends an automated reset-link to the user.
 */

export function ForgotPassword() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  // const [validated, setValidated] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // setValidated(true);
  };

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <NavLink to='/'>Back</NavLink>
      <div className={classes.paper}>
        <Typography component='h1' variant='h5'>
          Retrieve Forgotten Password
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            label='Email Address'
            autoFocus
            type='email'
            placeholder='Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
          >
            Submit
          </Button>
        </form>
      </div>
    </Container>
    // <div
    //   style={{
    //     margin: '50px auto 50px',
    //     maxWidth: '320px',
    //     backgroundColor: '#E7E7E7',
    //     padding: '35px 30px',
    //     borderRadius: '20px',
    //   }}
    // >
    //   <Row className='mb-3 ml-1'>
    //     <NavLink to='/'>Back</NavLink>
    //   </Row>
    //   <h5>Retrieve Forgotten Password</h5>
    //   <br />
    //   <Form noValidate validated={validated} onSubmit={handleSubmit}>
    //     <Form.Group controlId='email'>
    //       <Form.Label>Email</Form.Label>
    //       <Form.Control
    //         type='email'
    //         required
    //         placeholder='Enter Username or Email'
    //         value={email}
    //         onChange={(e) => setEmail(e.target.value)}
    //       />
    //       <Form.Control.Feedback type='invalid'>
    //         Email is required.
    //       </Form.Control.Feedback>
    //       <Form.Text className='text-muted'>
    //        Forgot PasswordText
    //       </Form.Text>
    //     </Form.Group>
    //     <Button variant='dark' type='submit'>
    //       Submit
    //     </Button>
    //   </Form>
    // </div>
  );
}
