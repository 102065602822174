/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useReducer } from 'react';
import { Prompt } from 'react-router';
import { useLayout, useLayoutDevice } from '../../layout';
import { SchedulesContext, schedulesReducer } from '..';
import { ScheduleView } from './'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card'
import { Button } from '@material-ui/core';
import { useAuthRequest } from '../../auth';
import { deviceApi } from '../../api/device.api';
import { NewSchedule } from './new-schedule.component';

/**
 * @description Component renders the Device Scheduling component.
 * @output Add Context Provider and Renders a main schedule setting component and schedule list.
 * @objective To render components for new and view current schedules
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: "0 2rem 2rem 2rem",
    },
    pageTitle:{
      margin: "2rem 0 3rem 0",
    },
    formButtons:{
      minWidth: "10rem",
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  })
);

export function Scheduling() {
  const classes = useStyles();
  const { setTitle, setDevice } = useLayout();
  const device = useLayoutDevice();

  const [ requestDevice, responseDevice ] = useAuthRequest<string>(
    (token, req) => {
      if (!req) throw new Error('Request Required');
      return deviceApi.getDevice(req, token);
    }
  );

  const [state, dispatch] = useReducer(schedulesReducer, {
    schedule: [],
    addNew: false,
    newStartTime: new Date(),
    newEndTime: new Date(),
    scheduleChanged: false
  });

  const [ requestUpdate, responseUpdate ] = useAuthRequest<string>(
    (token) => {
      const sch = {
        schedule: state.schedule,
        deviceId: device.id
      }
      return deviceApi.updateDeviceSchedule(device.id, sch, token);
    }
  );

  const handleSave = () => {
    dispatch({ type: 'changes-saved'})
    requestUpdate();
  }

  useEffect(() => {
    setTitle(`${device.name} / Scheduling`);
    setDevice(device);
  }, [device.name]);

  useEffect(() => {
    requestDevice(device.id);
  }, [device.id]);

  useEffect(() => {
    if(responseDevice && responseDevice.json) {
      dispatch({
        type: 'set-schedule',
        schedule: responseDevice.json.schedule || [] 
      })
    }
  }, [responseDevice]);

  return (<>
    <Prompt
      when={state.scheduleChanged}
      message='You have unsaved changes in schedule, are you sure you want to leave?'
    />
    <SchedulesContext.Provider value={{ state, dispatch }}>
      <Card className={classes.root}>
        <Button variant="contained" color="primary" className={classes.formButtons} onClick={()=>{ dispatch({ type: 'show-scheduler'})}}>ADD</Button>
        <NewSchedule />
        <ScheduleView />
        <Button variant="contained" color="primary" className={classes.formButtons} onClick={handleSave} disabled={!state.scheduleChanged}>SAVE CHANGES</Button>
      </Card>
    </SchedulesContext.Provider>
  </>)
}
