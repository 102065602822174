import { TranslationAction, TranslationState } from '../types';

export const translationReducer = (
  state: TranslationState,
  action: TranslationAction
): TranslationState => {
  switch (action.type) {
    case 'change': return {
      ...state,
      language: action.language
    }
    case 'set-translation': {
      const { translation } = state;
      translation[action.language] = action.translation;
      return {
        ...state,
        translation,
        language: action.language,
        fetching: false
      };
    }
    case 'fetching': return {
      ...state,
      fetching: true
    }
    default:
      throw new Error();
  }
};
