import { makeStyles } from '@material-ui/core/styles';
import { LedIndicatorProps } from '../types';
import { red, lightGreen, lightBlue, yellow, grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  red: {
    margin: "0 auto",
    width: 24,
    height: 24,
    backgroundColor: red['A400'],
    borderRadius: "50%"
  },
  blue: {
    margin: "0 auto",
    width: 24,
    height: 24,
    backgroundColor: lightBlue['A400'],
    borderRadius: "50%"
  },
  green: {
    margin: "0 auto",
    width: 24,
    height: 24,
    backgroundColor: lightGreen['A400'],
    borderRadius: "50%"
  },
  yellow:{
    margin: "0 auto",
    width: 24,
    height: 24,
    backgroundColor: yellow['A400'],
    borderRadius: "50%"
  },
  grey: {
    margin: "0 auto",
    width: 24,
    height: 24,
    backgroundColor: grey[200],
    borderRadius: "50%"
  }
}));


export function LedIndicator({ color }:LedIndicatorProps) {
  const className = useStyles()[color];
  return (
    <div>
      <div className={className}></div>
    </div>
  );
}