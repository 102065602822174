import { AppliedSpace } from '../types';
import { AnalogTag } from './analog-tag.component';
import { DigitalTag } from './digital-tag.component';

export const AutomatonTagAI = (props: { space: AppliedSpace, key?: string}) => {
    if(props.space.type === "A") return (<AnalogTag {...props}/>)
    else return(<></>);  
}
export const AutomatonTagDI = (props: { space: AppliedSpace, key?: string}) => {
    if(props.space.type === "D") return (<DigitalTag {...props}/>)
    else return(<></>); 
}