import { DatePickerView } from '@material-ui/pickers';
import { useEffect, useReducer, useState } from 'react';
import { LoggingRequestTypes } from '../../analytics/enums';
import { analyticsReducer, getAnalyticalTags } from '../../analytics/functions';
import { GetLogsRequest, logsApi } from '../../api';
import { useAuthRequest } from '../../auth';
import { useAutomatonTags } from '../../automaton-tags';
import { useLayoutContext, useLayoutDevice } from '../../layout';
import { reportTag } from '../types';
import {
  roundOff,
  capitalize,
  hourFormat,
  monthFormat,
  yearFormat,
  getSiteTitle,
} from '../../common';

/*
Monthly should have the dates
Yearly should have month names
*/

export const useDataGrid = () => {
  // initializing state for reports data
  const [reportsData, setReportsData] = useState({
    columns: [
      { field: 'id', hide: true, disableExport: true },
      { field: 'x', flex: 1, minWidth: 100 },
      { field: 'y', flex: 1, minWidth: 100 },
    ],
    rows: [],
  });

  const device = useLayoutDevice();
  const { state } = useLayoutContext();

  const [request, response] = useAuthRequest<GetLogsRequest>((token, req) => {
    if (!req) throw new Error('Request Required');
    return logsApi.getLogs(req, token);
  });

  const {
    state: { tagSetup },
  } = useAutomatonTags();

  const [analyticsState, dispatch] = useReducer(analyticsReducer, {
    selectedDate: new Date(),
    reqType: LoggingRequestTypes.daily,
    deviceId: device.id,
  });

  const [selectedTag, setSelectedTag] = useState<reportTag>(() => ({
    tag: getAnalyticalTags(tagSetup)[0].tag,
    name:
      getAnalyticalTags(tagSetup)[0].subHeader ??
      getAnalyticalTags(tagSetup)[0].tag,
  }));

  useEffect(() => {
    request({
      deviceId: device.id,
      tag: selectedTag.tag,
      year: analyticsState.selectedDate
        ? analyticsState.selectedDate.getFullYear()
        : new Date().getFullYear(),
      month: analyticsState.selectedDate
        ? analyticsState.selectedDate.getMonth()
        : new Date().getMonth(),
      day: analyticsState.selectedDate
        ? analyticsState.selectedDate.getDate()
        : new Date().getDate(),
      reqType: analyticsState.reqType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    device.id,
    analyticsState.selectedDate,
    analyticsState.reqType,
    selectedTag,
  ]);

  const formatXColumn = (label: any) => {
    const year = analyticsState.selectedDate
      ? analyticsState.selectedDate.getFullYear()
      : new Date().getFullYear();
    const month = analyticsState.selectedDate
      ? analyticsState.selectedDate.getMonth() + 1
      : new Date().getMonth() + 1;

    switch (analyticsState.reqType) {
      case LoggingRequestTypes.daily:
        return hourFormat(label);
      case LoggingRequestTypes.monthly:
        return monthFormat(label, month, year);
      case LoggingRequestTypes.yearly:
        return yearFormat(label);
      default:
        return label;
    }
  };

  const xLabelName = {
    DAILY: 'Hours',
    MONTHLY: 'Days',
    YEARLY: 'Months',
  };

  useEffect(() => {
    if (response) {
      const xColumnLabel = xLabelName[analyticsState.reqType];
      const yColumnLabel = capitalize(`${selectedTag.name} Values`);

      const columns = [
        { field: 'id', hide: true, disableExport: true },
        {
          field: xColumnLabel,
          flex: 1,
          align: 'center',
          headerAlign: 'center',
          minWidth: 100,
        },
        {
          field: yColumnLabel,
          flex: 1,
          align: 'center',
          headerAlign: 'center',
          minWidth: 100,
        },
      ];

      const rows = response.json.logs.map((log: any, index: any) => {
        const rowValue: { [key: string]: number } = {};
        rowValue[xColumnLabel] = formatXColumn(log.x);
        rowValue[yColumnLabel] = roundOff(log.y);
        return {
          id: index,
          ...rowValue,
        };
      });

      setReportsData({ ...reportsData, columns, rows });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const views: Record<LoggingRequestTypes, DatePickerView[]> = {
    [LoggingRequestTypes.yearly]: ['year'],
    [LoggingRequestTypes.monthly]: ['month'],
    [LoggingRequestTypes.daily]: ['date'],
  };

  function formatSwitch(param: any) {
    switch (param[0]) {
      case 'month':
        return 'MM/yyy';
      case 'date':
        return 'dd/MM/yyy';
      case 'year':
        return 'yyyy';
      default:
        return 'dd/MM/yyyy';
    }
  }

  const isReqType = (
    requestType: unknown
  ): requestType is LoggingRequestTypes =>
    Object.values(LoggingRequestTypes).includes(
      String(requestType) as LoggingRequestTypes
    );

  const fileName = `${getSiteTitle(state.title)} - ${capitalize(
    analyticsState.reqType
  )} - ${selectedTag.name}`;

  return {
    reportsData,
    formatSwitch,
    fileName,
    views,
    analyticsState,
    dispatch,
    response,
    isReqType,
    setSelectedTag,
    tagSetup,
  };
};
