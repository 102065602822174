import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { Dispatch, SetStateAction } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { LoggingRequestTypes } from '../../analytics/enums';
import { AnalyticsState, AnalyticsAction } from '../../analytics/types';
import { getAnalyticalTags } from '../../analytics/functions';
import { useDataGrid } from '../hooks';
import { reportTag } from '../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: '2%',
      marginLeft: '2%',
    },
  })
);

export const ReportsToolBar = ({
  analyticsState,
  setSelectedTag,
  dispatch,
}: {
  analyticsState: AnalyticsState;
  setSelectedTag: Dispatch<SetStateAction<reportTag>>;
  dispatch: Dispatch<AnalyticsAction>;
}) => {
  const { formatSwitch, views, isReqType, tagSetup } = useDataGrid();
  const classes = useStyles();

  return (
    <Grid
      className={classes.container}
      container
      justifyContent='center'
      alignItems='center'>
      <Grid item xs={7} md={4}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            autoOk
            views={views[analyticsState.reqType]}
            label='Pick Date'
            format={formatSwitch(views[analyticsState.reqType])}
            value={analyticsState.selectedDate}
            onChange={(date) =>
              dispatch({ type: 'set-date', selectedDate: date })
            }
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={7} md={4}>
        <FormControl>
          <InputLabel id='type-select-outlined-label'>Type</InputLabel>
          <Select
            labelId='type-select-outlined-label'
            value={analyticsState.reqType}
            onChange={(event) =>
              dispatch({
                type: 'set-req-type',
                reqType: isReqType(event.target.value)
                  ? event.target.value
                  : LoggingRequestTypes.daily,
              })
            }
            label='Type'>
            <MenuItem value={LoggingRequestTypes.yearly}>Yearly</MenuItem>
            <MenuItem value={LoggingRequestTypes.monthly}>Monthly</MenuItem>
            <MenuItem value={LoggingRequestTypes.daily}>Daily</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={7} md={4}>
        {' '}
        <InputLabel shrink>Device</InputLabel>
        <Select autoWidth displayEmpty style={{ width: '170.19px' }}>
          {getAnalyticalTags(tagSetup).map((tag) => (
            <MenuItem
              onClick={() =>
                setSelectedTag({
                  tag: tag.tag,
                  name: tag.subHeader,
                })
              }
              value={tag.tag}>
              {tag.subHeader}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
};
