import { useEffect, useState } from 'react';
import { UsePeriodicAuthRequest } from '../types';
import { useAuthRequest } from './use-auth-request.hook';

export const usePeriodicAuthRequest: UsePeriodicAuthRequest = (request, period) => {
    const [authRequest, response, error, isLoading] = useAuthRequest(request);
    const [firstRun, setFirstRun] = useState(true); // to be always initialized as true to indecate first run

    // Set up the interval.
    useEffect(() => {
        if (isLoading) return;
        if (firstRun) {
            authRequest();
            setFirstRun(false); // never again set to ture after first run 
            return;
        }
        const id = setTimeout(() => {
            authRequest();
        }, period);
        return () => {
            clearTimeout(id);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period, isLoading, firstRun]);

    return [response, error];
}