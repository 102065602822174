import { AnalyticsAction, AnalyticsState } from '../types';

export const analyticsReducer = (
  state: AnalyticsState,
  action: AnalyticsAction
): AnalyticsState => {
  switch (action.type) {
    case 'set-date':
      return {
        ...state,
        selectedDate: action.selectedDate
      };
    case 'set-req-type':
      return {
        ...state,
        reqType: action.reqType
      };    
    case 'set-device-Id': return {
      ...state,
      deviceId: action.deviceId
    }  
    default:
      throw new Error();
  }
};
