import React, { useState } from 'react';
import { useLogin } from '../hooks';
import { Copyright } from '../../common';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

/**
 * @description Component Logs user into the system.
 * @output Renders two input fields take in email and password,
 * saves them to state,  and calls back-end API for user Login.
 * Then it calls context-dispatch that updates the context, and saves resulting token/hash (currently hardcoded) in sessionsStorage
 */
const useStyles = makeStyles((theme) => ({
  logoCaption: {
    color: 'grey', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },

  login: {
    margin: theme.spacing(5, 2, 5, 2),
    background: '#036899',
    color: 'white',
  },
  submit: {
    margin: theme.spacing(5, 2, 5, 2),
    borderColor: '#036899',
    color: '#036899',
  },
  formButtons: {
    display: 'flex',
    flexDirection: 'row',
    textDecoration: 'none',
  },
  waterImage: {
    width: '100%',
    height: '95vh',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  loginForm: {
    [theme.breakpoints.down('md')]: {
      paddingBottom: '130px',
      paddingTop: '10px',
    },
  },
}));

export function LoginForm({ setLoggedIn }: any) {
  const classes = useStyles();

  const { login, error } = useLogin()

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [org, setOrg] = useState('');


  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      login({
        org: org.toUpperCase(),
        username,
        password,
      })
    }
  };

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      className={classes.loginForm}
    >
      <Grid item lg={6}>
        <img src='images/water-landing.jpg' alt='water' className={classes.waterImage} />{' '}
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={12}
        lg={6}
        direction='column'
        justifyContent='center'
        alignItems='center'
        className={classes.loginForm}
      >
        <Grid item>
          <img src='images/authLogo.svg' alt='water' />{' '}
        </Grid>
        <Grid item>
          <Typography
            variant='caption'
            display='block'
            className={classes.logoCaption}
          >
            Welcome Back! Please Log in to your account.
          </Typography>
        </Grid>
        <Grid item>
          <form onSubmit={handleSubmit}>
            <TextField
              margin='normal'
              required
              fullWidth
              label='Organization Code'
              autoFocus
              type='text'
              placeholder='Organization'
              value={org}
              onChange={(e) => setOrg(e.target.value)}
            />
            <TextField
              margin='normal'
              required
              fullWidth
              label='Username'
              type='text'
              placeholder='Username'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              margin='normal'
              required
              fullWidth
              type='password'
              placeholder='Password'
              value={password}
              label='Password'
              onChange={(e) => setPassword(e.target.value)}
            />

            <Grid item className={classes.formButtons}>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                className={classes.login}
              >
                Login
              </Button>
            </Grid>
          </form>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
          </Grid>
          {
            error 
              ? (<p>Please try again</p>)
              : null
          }
        </Grid>
      </Grid>
      <Copyright />
    </Grid>
  );
}
