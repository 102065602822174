import { useContext } from 'react';
import { AutomatonTagsContext } from '../contexts';
import { stringifyClone } from '../../lib'

export const useAutomatonTags = () => {
    const { state } = useContext(AutomatonTagsContext);
    return {
        state,
        getTagSetup: (tagName: string) => {
            const tsp = state.tagSetup[tagName];
            if (!tsp) return {
                label: tagName,
                image: 'images/logo.png',
                type: 'D'
            }
            return tsp
        },
        getSetup: () => stringifyClone(state.tagSetup)
    }
}