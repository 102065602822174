import { AutomatonTagsAction, AutomatonTagsState } from "../types";

export const automatonTagsReducer = (
  state: AutomatonTagsState,
  action: AutomatonTagsAction
): AutomatonTagsState => {
  switch (action.type) {
    case "set-automaton-tags": {
      return {
        ...state,
        tagSetup: action.tagSetup,
        fetching: false,
      };
    }
    case "set-profile-tags": {
      return {
        ...state,
        profileSetup: action.profileSetup,
        fetching: false,
      };
    }
    case "fetching":
      return {
        ...state,
        fetching: true,
      };
    default:
      throw new Error();
  }
};
