import CssBaseline from "@material-ui/core/CssBaseline";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles, useTheme, Theme, createStyles } from "@material-ui/core/styles";
import { useLayoutContext } from "../hooks";
import { NavBarItem } from "../types";
import { SideNavDrawer } from "./side-nav-drawer.component";

const drawerWidth = 235;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      overflowX: "hidden",
      width: drawerWidth,
      backgroundColor: "#036899",
      color: "white",
    },
  })
);

export function SideNavBar(props: { items: NavBarItem[] }) {
  const classes = useStyles();
  const theme = useTheme();
  const { state, dispatch } = useLayoutContext();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <SideNavDrawer
            items={props.items}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={state.openDeviceNav}
            onClose={() => dispatch({ type: "open-device-nav", open: false })}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          />
        </Hidden>
        <Hidden xsDown implementation="css">
          <SideNavDrawer
            items={props.items}
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          />
        </Hidden>
      </nav>
    </div>
  );
}
