import { LoaderDiv } from './loader-div.component';
import { ErrorDiv } from './error-div.component';
import { AsyncLoaderProps } from '../types';

export const AsyncLoader = ({ response, error, render }: AsyncLoaderProps) => {
	return (
		<>
			{error ? <ErrorDiv /> : response ? render(response) : <LoaderDiv />}
		</>
	);
};
