import { roundOff } from './roundOff.function';

const toKiloRepresentation = (
  numberToConvert: number,
  numberOfPoints: number = 1
): string => {
  if (numberToConvert >= 1000) {
    return `${roundOff(numberToConvert / 1000, numberOfPoints)}k`;
  } else return `${roundOff(numberToConvert, numberOfPoints)}`;
};

export { toKiloRepresentation };
