import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import BlockIcon from '@material-ui/icons/Block';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { deepOrange, grey, blue, green } from '@material-ui/core/colors';

const borderRadius = 6;

const useStyles =  makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  summary: {
    minWidth: "100%",
    padding: 5,
    display: "inline"
  },
  totalSites: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  online: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[600],
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  offline: {
    color: theme.palette.getContrastText(grey[600]),
    backgroundColor: grey[600],
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  alarm: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  totalDataDisplay: {
    marginBottom: theme.spacing(1),
    borderRadius,
    padding: theme.spacing(2),
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
  },
  onlineDataDisplay: {
    marginBottom: theme.spacing(1),
    borderRadius,
    padding: theme.spacing(2),
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[600],
  },
  offlineDataDisplay: {
    marginBottom: theme.spacing(1),
    borderRadius,
    padding: theme.spacing(2),
    color: theme.palette.getContrastText(grey[600]),
    backgroundColor: grey[600],
  },
  alarmDataDisplay: {
    marginBottom: theme.spacing(1),
    borderRadius,
    padding: theme.spacing(2),
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  primary: {
    fontSize: 30,
    color: 'white',
    textAlign: 'center'

  },
  secondary: {
    fontSize: 30,
    fontWeight: 'bold',
    color: 'white'
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  }
}));

export function SummaryList(props: { online: number, offline: number, alarms: number}) {
  const classes = useStyles();
  return (   
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <List className={classes.root}>
          <ListItem className={classes.totalDataDisplay} >
            <ListItemAvatar>
              <Avatar className={classes.totalSites}>
                <AccountBalanceIcon style={{ fontSize: 50}}/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Total Sites" classes={{
              secondary: classes.secondary,
              primary: classes.primary
            }}/>
            <h1>{props.online + props.offline}</h1>
          </ListItem>
          <ListItem className={classes.onlineDataDisplay}>
            <ListItemAvatar  >
              <Avatar className={classes.online} >
                <CloudDoneIcon style={{ fontSize: 50}}/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Total Online Sites" classes={{
              secondary: classes.secondary,
              primary: classes.primary
            }}/>
            <h1>{props.online}</h1>
          </ListItem>
        </List>
      </Grid>
      <Grid item  xs={12} md={6}>
        <List className={classes.root}>
          <ListItem className={classes.offlineDataDisplay}>
            <ListItemAvatar>
              <Avatar className={classes.offline}>
                <BlockIcon style={{ fontSize: 50}} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Total Offline Sites" classes={{
              secondary: classes.secondary,
              primary: classes.primary
            }}/>
            <h1>{props.offline}</h1>
          </ListItem>
          <ListItem className={classes.alarmDataDisplay}>
            <ListItemAvatar>
              <Avatar className={classes.alarm}>
                <AccessAlarmIcon  style={{ fontSize: 50}}/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Total Sites With Alarms" classes={{
              secondary: classes.secondary,
              primary: classes.primary
            }}/>
            <h1>{props.alarms}</h1>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
}