import { setAccessToken, setRefreshToken, setUserInfo } from '../functions';

export const saveAuthResponse = (username: string, response: any) => {
    const { context: { uid, role, org }, accessToken, refreshToken } = response;
    setUserInfo({
        username,
        userId: uid,
        userRole: role,
        org,
    });
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
}