/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useEffect } from 'react';
import { translationReducer } from '../functions';
import { TranslationContext } from '../contexts';
import { WithTranslationInputProps } from '../types';
import { LoaderDiv } from '../../common/components';
import { useRequest } from '../../common/hooks';
import { get } from '../../lib';

export const TranslationProvider = ({
  children, language = 'en', baseFolder = 'locales'
}: { children: React.ReactNode } & WithTranslationInputProps) => {
  const [state, dispatch] = useReducer(translationReducer, {
    baseFolder, 
    language,
    fetching: true,
    translation: {}
  });
  const [ request, response, error ] = useRequest(
    () => get(`${state.baseFolder}/${state.language}/translation.json`)
  );

  useEffect(() => {
    if (!state.translation[state.language]) {
      request();
      dispatch({ type: 'fetching'});
    }
  }, [state.language]);

  useEffect(() => {
    if (response) {
      try {
        const translation = JSON.parse(response.text);
        dispatch({ type: 'set-translation', language: state.language, translation});
      } catch (error) {
        dispatch({ type: 'set-translation', language: state.language, translation: {}});  
      }
    }
    if (error){
      dispatch({ type: 'set-translation', language: state.language, translation: {}});
    }
  }, [response, error]);

  return (
    <TranslationContext.Provider value={{ state, dispatch}}>
      {
        state.fetching 
          ? (<LoaderDiv />)
          : children 
      }
    </TranslationContext.Provider>
  );
};