/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useAutomatonTags } from '../../automaton-tags';
import { GraphCard } from '../../analytics/components/graph-card.component';
import { getAnalyticalTags } from '../../analytics/functions';
import { Device } from '../../devices/types';
import { useStatusBoardContext } from '../hooks';
import { useState } from 'react';
import { useInterval } from '../../common';
import { StatusBoardAction } from '../types';

type DeviceAnalyticssCardProps = {
  device: Device;
  dispatch: (value: StatusBoardAction) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
);

export function DeviceAnalyticsCard({
  device,
  dispatch,
}: DeviceAnalyticssCardProps) {
  const {
    state: { refresh },
  } = useStatusBoardContext();
  const classes = useStyles();
  const {
    state: { tagSetup },
  } = useAutomatonTags();

  const tagsList = getAnalyticalTags(tagSetup);
  const [tagIndex, setTagIndex] = useState(0);

  useInterval(() => {
    if (tagIndex < tagsList.length - 1) {
      setTagIndex(tagIndex + 1);
    } else {
      dispatch({ type: 'refresh' });
      setTagIndex(0);
    }
  }, 10 * 1000); // changes index after this interval

  return (
    <Grid
      container
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      className={classes.root}>
      { tagsList && tagsList.length > 0 
          ? (<GraphCard
              graphHeight={300}
              anaTag={tagsList[tagIndex]}
              key={tagsList[tagIndex].tag}
              deviceId={device._id}
              refresh={refresh}
            />)
          : <></>
      }
    </Grid>
  );
}
