import { HashRouter as Router } from 'react-router-dom';
import { AuthenticationProvider } from './auth';
import { TranslationProvider } from './translation';
import { LayoutProvider } from './layout';
import { AutomatonTagsProvider } from './automaton-tags';

import './App.css';

export default function App() {
  return (
    <TranslationProvider>
      <Router>
        <AuthenticationProvider>
          <AutomatonTagsProvider>
            <LayoutProvider />
          </AutomatonTagsProvider>
        </AuthenticationProvider>
      </Router>
    </TranslationProvider>
  );
}
