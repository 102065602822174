import { useEffect } from 'react';
import { LoaderDiv } from '../../common';
import { useRelogin } from '../hooks/use-relogin.hook';


export const Relogin = () => {
   const [request, response, error] = useRelogin();
    useEffect(() => {
        request();
    })
    return (<>
        {
            response || error
                ? null
                : <LoaderDiv />
        }
    </>);
};
