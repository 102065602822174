import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { AppliedSpace } from '../types';
import { LedIndicator } from '../../common';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		frostedSensorCard: {
			border: "1px solid #fff",
			borderRadius: "10px",
			boxShadow: "0 0 1rem 0 rgba(0, 0, 0, 0.2)",
			overflow: "hidden",
			backgroundColor: "white",
			backdropFilter: "blur(10px) saturate(100%) contrast(45%) brightness(130%)"
		},
		frostedSensorCardLabel: {
			
			padding: "2rem"
		},
		frostedSensorCardLogo: {
			marginLeft: "20%",
			marginRight: "20%"
		},
		frostedSensorCardTitle: {
			fontWeight: "bold",
			fontSize: "15px",
			marginLeft: "10%",
			marginRight: "10%",
			paddingLeft: "40%",
			paddingRight: "20%",
			width: "max-content"
		},
		frostedStatusText: {
			margin: "auto",
			textAlign: "center",
			fontSize: "14px",
			padding: "0.2rem"
		}
				
	}),
);

const TagLogo = (props: { src: string, alt: string}) => {
	const classes = useStyles();
	return (
	<img
		src={props.src}
		className={classes.frostedSensorCardLogo}
		alt={props.alt}
	/>
	);
}

const TagStatusLed = (props: { state: string }) => {
	switch(props.state) {
		case 'ONLINE': return <LedIndicator color={'green'} />;
		case 'OFFLINE': return <LedIndicator color={'yellow'} />;
		case 'ERROR': return <LedIndicator  color={'red'} />;
		default: return <LedIndicator  color={'red'} />;
	}
}

export const DigitalTag = ({space, key}: { space: AppliedSpace, key?: string}) => {
	const classes = useStyles();
	return (
		<Grid item xs={12} sm={6} md={4} lg={4} key={key}>
			<div className={classes.frostedSensorCard}>
				<Grid
					container
					item
					className={classes.frostedSensorCardLabel}
					justifyContent='space-between'
					alignItems='center'
				>
					<Grid container item alignItems='center' xs={12} sm={9}>
						<Grid> <TagLogo src={space.image} alt={space.label}/> </Grid>
						<Grid>
							<p className={classes.frostedSensorCardTitle}>{space.label}</p>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={3}>
						<div><TagStatusLed state={space.status}/></div>
						<div className={classes.frostedStatusText}>{space.status}</div>
					</Grid>
				<Grid item xs={12} sm={3}>
					<Chip label={space.value} color="primary" variant="outlined" style={{ minWidth: "60px"}}/>
				</Grid>

				</Grid>
			</div>
		</Grid>
	);
};
