import { useLayoutContext } from './use-layout-context.hook';
import { NavDeviceInfo } from '../types';

export const useLayout = () => {
    const { state, dispatch } = useLayoutContext();
    return {
        state,
        setTitle: (title: string) => dispatch({ type: 'set-title', title }),
        setDevice: (device: NavDeviceInfo | null) => dispatch({ type: 'set-device', device })
    }
}