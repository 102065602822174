import { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Device } from '../types';
import { DeviceTable } from './device-table.component';
import FormControl from '@material-ui/core/FormControl';
import { deviceApi } from "../../api";
import { useAuthRequest } from '../../auth';
import { useInterval, StyledRadio } from '../../common';
import { SummaryFields } from '../enums';
import { SummaryList } from './summary-list.component';
import { siteDataConverter } from '../functions';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';

type DeviceKanbanProps = {
    devices: Device[];
    alarmSound?: boolean;
}

const audio = new Audio('audio/mixkit-classic-short-alarm-993.wav');

export function DeviceSummary({ devices, alarmSound = false}: DeviceKanbanProps) {
  const [tableType, setTableType] = useState<SummaryFields >(SummaryFields.alarms);
  const [ request, response ] = useAuthRequest(
    (token) => deviceApi.allDevices(token)
  );
  const [summary, setSummary] = useState(siteDataConverter(devices));

  const formSelect = (
  <Paper style={{ padding: 10 }}>
    <FormControl component="fieldset">
      <FormLabel component="legend">Summary Table</FormLabel>
      <RadioGroup row name="summaryType" value={tableType} onChange={(event) => setTableType(event.target.value as SummaryFields)}>
        <FormControlLabel value={SummaryFields.alarms} control={<StyledRadio />} label="In Alarm" />
        <FormControlLabel value={SummaryFields.all} control={<StyledRadio />} label="All Sites" />
        <FormControlLabel value={SummaryFields.online} control={<StyledRadio />} label="Online" />
        <FormControlLabel value={SummaryFields.offline} control={<StyledRadio />} label="Offline" />
      </RadioGroup>
    </FormControl>
  </Paper>
);
  useEffect(() => {
    if(alarmSound && summary.alarms.length){
      setTableType(SummaryFields.alarms);
      audio.play();
    } 
  }, [alarmSound, summary.alarms.length]);

  useInterval(() => {
    request();
  }, 10 * 1000); // changes index after this interval

  useEffect(() => {
    if(response){
      setSummary(siteDataConverter(response.json));
    }
  }, [response]);

  return (
    <Grid container spacing={4}>
      <Grid container item xs={12}>    
        <SummaryList offline={summary.offline.length} online={summary.online.length} alarms={summary.alarms.length}/>
      </Grid>
      <Grid item xs={12}> 
        {formSelect}
      </Grid>
      <Grid item xs={12}>   
        <DeviceTable devices={summary[tableType]}/>
      </Grid>
    </Grid>
  );
}