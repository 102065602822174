import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { AppliedSpace } from '../types';
import { roundOff } from '../../common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    metric: {
      minHeight: '254px',
      backgroundColor: '#fff',
      borderRadius: '10px',
      boxShadow: '0 10px 10px rgba(0, 0, 0, 0.2)',
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '100%',
      overflow: 'hidden',
    },
    metricLeft: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    metricPreview: {
      color: 'black',
      fontWeight: 'bold',
      padding: '20px 0px 20px 30px',
    },
    metricIcon: {
      alignDelf: 'flex-end',
      margin: '10%',
    },
    metricInfo: {
      padding: '30px',
      position: 'relative',
      width: '100%',
    },
    metricReading: {
      fontSize: '2rem',
      fontWeight: 'bold',
      color: '#036899',
      marginRight: '7px',
    },
    metricMeasurement: {
      fontSize: '1rem',
      color: '#036899',
    },
  })
);

export const AnalogTag = ({
  space,
  key,
}: {
  space: AppliedSpace;
  key?: string;
}) => {
  const classes = useStyles();
  const [value, unit] = space.value.split(' ');
  return (
    <Grid item xs={12} sm={12} md={6} lg={4} key={key}>
      <div className={classes.metric}>
        <div className={classes.metricLeft}>
          <div className={classes.metricPreview}>
            <span>{space.label}</span>
          </div>
          <div className={classes.metricInfo}>
            <span className={classes.metricReading}>
              {roundOff(parseFloat(value), 3)}
            </span>
            <span className={classes.metricMeasurement}>{unit}</span>
          </div>
        </div>
        <div className={classes.metricIcon}>
          <img src={space.image} alt={space.label} />
        </div>
      </div>
    </Grid>
  );
};
