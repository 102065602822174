/* eslint-disable react-hooks/exhaustive-deps */
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker,
  DatePickerView,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { useAnalyticsContext } from '../hooks';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { useInterval } from '../../common';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { LoggingRequestTypes } from '../enums';

const isReqType = (val: unknown): val is LoggingRequestTypes =>
  Object.values(LoggingRequestTypes).includes(
    String(val) as LoggingRequestTypes
  );

const views: Record<LoggingRequestTypes, DatePickerView[]> = {
  [LoggingRequestTypes.yearly]: ['year'],
  [LoggingRequestTypes.monthly]: ['month'],
  [LoggingRequestTypes.daily]: ['date'],
};

function formatSwitch(param: any) {
  switch (param[0]) {
    case 'month':
      return 'MM/yyy';
    case 'date':
      return 'dd/MM/yyy';
    case 'year':
      return 'yyyy';
    default:
      return 'dd/MM/yyyy';
  }
}

export const GraphToolBar = () => {
  const { state, dispatch } = useAnalyticsContext();
  const today = new Date(),
    tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 1, 0);
  const milliSecondsToMidnight = tomorrow.getTime() - today.getTime();
  useInterval(
    () => dispatch({ type: 'set-date', selectedDate: new Date() }),
    milliSecondsToMidnight
  );
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          autoOk
          views={views[state.reqType]}
          variant='inline'
          inputVariant='outlined'
          label='Pick Date'
          format={formatSwitch(views[state.reqType])}
          value={state.selectedDate}
          onChange={(date) =>
            dispatch({ type: 'set-date', selectedDate: date })
          }
        />
      </MuiPickersUtilsProvider>
      <FormControl variant='outlined'>
        <InputLabel id='type-select-outlined-label'>Type</InputLabel>
        <Select
          labelId='type-select-outlined-label'
          value={state.reqType}
          onChange={(event) =>
            dispatch({
              type: 'set-req-type',
              reqType: isReqType(event.target.value)
                ? event.target.value
                : LoggingRequestTypes.daily,
            })
          }
          label='Type'
        >
          <MenuItem value={LoggingRequestTypes.yearly}>Yearly</MenuItem>
          <MenuItem value={LoggingRequestTypes.monthly}>Monthly</MenuItem>
          <MenuItem value={LoggingRequestTypes.daily}>Daily</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};
