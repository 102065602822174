import Grid from '@material-ui/core/Grid';
import { useEffect, useState } from 'react';
import { deviceApi } from '../../api/device.api';
import { useAuthRequest } from '../../auth/hooks/use-auth-request.hook';
import { useInterval } from '../../common/hooks/use-interval.hook';
import { Device } from '../types';
import { DeviceLiveCard } from './device-live-card.component';

type DeviceLiveProps = {
    devices: Device[]
}

export function DeviceLive({ devices }: DeviceLiveProps) {
  const [refreshedDevices, setRefreshedDevices] = useState<Device[]>(devices)
  const [ request, response ] = useAuthRequest(
    (token) => deviceApi.allDevices(token)
  );
  useInterval(() => {
    request();
  }, 10 * 1000); // changes index after this interval

  useEffect(() => {
    if(response){
      console.log('Refreshed')
      setRefreshedDevices(response.json);
    }
  }, [response]);
  return (
    <div >
      <Grid container spacing={1}>
        <Grid container  item xs={12} spacing={1}>   
          {
            refreshedDevices.sort((a,b) => a.code > b.code ? 1 : -1).map((d) => ( <Grid item xs={12} sm={2} key={d._id}>
              <DeviceLiveCard device={d} />
            </Grid>))
          }
        </Grid>
      </Grid>
    </div>
  );
}