import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { deepOrange, grey, green } from '@material-ui/core/colors';
import { useLayoutNav } from '../../layout';
import { Device } from '../types';

type DeviceTabeProps = {
  devices: Device[]
}

const useStyles =  makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableHeadCell:{
    backgroundColor: "#036899",
    color: theme.palette.common.white,
    [theme.breakpoints.up('lg')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
  },
  tableBodyCell:{
    [theme.breakpoints.up('lg')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
  },
  tableIcon:{
    color: "#02537a"
  },
  onlineCell: {
    color:"white",
    backgroundColor: green[600]
  },
  offlineCell: {
    color:"white",
    backgroundColor: grey[600]
  },
  alarmCell: {
    color:"white",
    backgroundColor: deepOrange[500]
  }
}));

export function DeviceTable({ devices }: DeviceTabeProps) {
  const classes = useStyles();
  const navigateToDevice = useLayoutNav();
  const getCellClass = (device: Device) => {
    if(device.status === 'OFFLINE') return classes.offlineCell;
    if(device.status === 'ONLINE') {
      if(device.tags.some(tag => Boolean(tag.alarm))){
        return classes.alarmCell;  
      }
      return classes.onlineCell;
    }
  }
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead >
          <TableRow >
            <TableCell className={classes.tableHeadCell} align="center">Code</TableCell>
            <TableCell className={classes.tableHeadCell} align="center">Status</TableCell>
            <TableCell className={classes.tableHeadCell} align="center">Alarm</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {devices.length === 0 
            ? <TableRow ><TableCell align="center" colSpan={3}>No Devices In this State</TableCell></TableRow>
            : devices.map((row, index) => (
            <TableRow key={index} onClick={()=>navigateToDevice({ id: row._id, name: row.code })} >
              <TableCell className={classes.tableBodyCell} align="center">{row.code}</TableCell>
              <TableCell className={getCellClass(row)} align="center">{row.status}</TableCell>
              <TableCell className={classes.tableBodyCell} align="center">{row.tags.find(tag => Boolean(tag.alarm))?.alarm}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
