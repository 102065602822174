import { useContext } from 'react';
import { TranslationContext } from '../contexts';
import { objectValue } from '../functions';

export const useTranslation = () => {
    const { state, dispatch } = useContext(TranslationContext);
    return {
        t: (value?: string | null) => {
            if (!value) return value;
            return objectValue(value, state.translation[state.language]) || value 
        },
        changeLanguage: (language: string) => dispatch({ type: 'change', language }),
        language: state.language
    }
}