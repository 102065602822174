import { useEffect, useState } from 'react';
import { deviceApi } from '../../api';
import { usePeriodicAuthRequest } from '../../auth';
import { useLayout, useLayoutDevice } from '../../layout';
import Grid from '@material-ui/core/Grid';
import { useAutomatonTags, applyTagSpace, AutomatonTagAI, AutomatonTagDI, AppliedSpace } from '../../automaton-tags';

export const DeviceDashboard = () => {
    const { setTitle, setDevice  } = useLayout();
    const device = useLayoutDevice();
    const [deviceData, setDeviceData] = useState<any[]>([]);
    const { getSetup } = useAutomatonTags();

    const [ response ] = usePeriodicAuthRequest((token)=>{
        return deviceApi.getRealTimeData(device.id, token)
    }, 11000); // refreshing every 11 seconds 

    useEffect(() => {
        setTitle(`${device.name} / Dashboard`);
        setDevice(device);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [device.name]);
    
    useEffect(() => {
        if (response) {
            applyTagSpace(getSetup(), response.json)
                .then((space) => setDeviceData(space))
                .catch((error) => console.log(error))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response])
    return (
        <Grid container spacing={6}>
            <Grid container item xs={12} spacing ={2}>  
                {
                    deviceData && deviceData.map((d: AppliedSpace) => (<AutomatonTagDI space={d} key={d.label}/>))
                }
            </Grid>
            <Grid container item xs={12} spacing ={2}>
                {
                    deviceData && deviceData.map((d: AppliedSpace) => (<AutomatonTagAI space={d} key={d.label}/>))
                }
            </Grid>
        </Grid>
    );    
}